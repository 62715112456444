import Repository from './Repository';

export default {

	async generateFoundationReport(studentOrganisationUserRoleId, organisationUserRoleId, term, qualificationId, periodId, languageId, comment) {
		var response = await Repository.get('Report/GenerateFoundationReport', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {	
				studentOrganisationUserRoleId: studentOrganisationUserRoleId,
				organisationUserRoleId: organisationUserRoleId,
				term: term,
				qualificationId: qualificationId,
				periodId: periodId,
				languageId: languageId,
				comment: comment
			}
		});
		if (response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return JSON.parse(decoder.decode(response.data))
		}
		return response;
	},

	async generateProgressReport(organisationUserRoleId, studentOrganisationUserRoleId, qualificationId, periodId) {
		var response = await Repository.get('Report/GenerateProgressReport', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {
				organisationUserRoleId: organisationUserRoleId,
				studentOrganisationUserRoleId: studentOrganisationUserRoleId,
				qualificationId: qualificationId,
				periodId: periodId
			}
		});
		if (response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return JSON.parse(decoder.decode(response.data))
		}
		return response;
	},

	async generateReport(studentOrganisationUserRoleId, organisationUserRoleId, term, qualificationId, periodId, languageId, comment) {
		var response = await Repository.get('Report/GenerateReport', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {	
				studentOrganisationUserRoleId: studentOrganisationUserRoleId,
				organisationUserRoleId: organisationUserRoleId,
				term: term,
				qualificationId: qualificationId,
				periodId: periodId,
				languageId: languageId,
				comment: comment
			}
		})
		if (response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return JSON.parse(decoder.decode(response.data))
		}
		return response;
	},

	async generateSubmissionReport(organisationUserRoleId, studentOrganisationUserRoleId, qualificationId, periodId) {
		var response = await Repository.get('Report/GenerateSubmissionReport', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {
				organisationUserRoleId: organisationUserRoleId,
				studentOrganisationUserRoleId: studentOrganisationUserRoleId,
				qualificationId: qualificationId,
				periodId: periodId
			}
		});
		if (response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return JSON.parse(decoder.decode(response.data))
		}
		return response;
	},

	async generateTCSubmissionReport(organisationUserRoleId, organisationId, dateStart, dateEnd, periodId, qualificationId) {
		var response = await Repository.get('Report/GenerateTCSubmissionReport', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				organisationUserRoleId: organisationUserRoleId,
				organisationId: organisationId,
				dateStart: dateStart,
				dateEnd: dateEnd,
				periodId: periodId,
				qualificationId: qualificationId
			}
		});
		if (response.headers['content-type'].includes('application/json')) {
			const decoder = new TextDecoder()
			return JSON.parse(decoder.decode(response.data))
		}
		return response;
	},

	getReportStatus(organisationUserRoleId, studentOrganisationUserRoleId, periodId) {
		var response = Repository.get('Report/GetReportStatus', {		
			params: {
				organisationUserRoleId: organisationUserRoleId,
				studentOrganisationUserRoleId: studentOrganisationUserRoleId,
				periodId: periodId
			}
		});
		return response;
	},

	getOrganisationRoleUsersByQualification(organisationId, roleId, pageNumber, pageSize, searchString) {
		var response = Repository.get('User/GetOrganisationRoleUsersByQualification', {		
			params: {
				organisationId: null,
				roleId: roleId,
				pageNumber: pageNumber,
				pageSize: pageSize,
				searchString: searchString
			}
		});
		return response;
	},

	unlockFinalYearReport(organisationUserRoleId, studentOrganisationUserRoleId ) {
		var response = Repository.patch('Report/UnlockFinalYearReport', {
			studentOrganisationUserRoleId: parseInt(studentOrganisationUserRoleId),
			organisationUserRoleId: parseInt(organisationUserRoleId)
		});
	
		return response;
	},

	// /api/v{version}/Report/GetReportLock
	getReportLock(organisationId, periodId, term, qualificationId) {
		var response = Repository.get('Report/GetReportLock', {		
			params: {
				organisationId: organisationId,
				periodId: periodId,
				name: term,
				qualificationId: qualificationId
			}
		});
		return response;
	},

	createOrUpdateReportLock(organisationId, name, value, periodId, description, qualificationItems) {
		var response = Repository.post('Report/CreateOrUpdateReportLock', {
			organisationId: organisationId,
			name: name,
			value: value,
			periodId: periodId,
			description: description,
			qualificationItems: qualificationItems
		});
		return response;		
	},


}
