import Repository from './Repository';

export default {

	getCourseOnlineClasses(courseId, organisationUserRoleId) {
		var response = Repository.get('OnlineClass/GetCourseOnlineClasses', {
			params: {
				courseId: courseId,
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	deleteOnlineClass(onlineClassId) {
		var response = Repository.delete('OnlineClass/DeleteOnlineClass', {
			params: {		
				onlineClassId: onlineClassId
			}
		});
		return response;		
	},

	getModeratorLink(onlineClassId) {
		var response = Repository.get('OnlineClass/GetModeratorLink', {
			params: {
				onlineClassId: onlineClassId
			}			
		});
		return response;		
	},

	getPublicLink(onlineClassId) {
		var response = Repository.get('OnlineClass/GetPublicLink', {
			params: {
				onlineClassId: onlineClassId
			}			
		});
		return response;		
	},

	getOnlineClasses(curriculumId, languageIds, periodId, qualificationId, subjectId, dateFrom, dateTo) {
		var response = Repository.get('OnlineClass/GetOnlineClasses', {
			params: {
				curriculumId: curriculumId,
				languageIds: languageIds.join(','),
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId,
				dateFrom: dateFrom,
				dateTo: dateTo,
			}			
		});
		return response;		
	},


	joinOnlineClass(userId, onlineClassId, organisationUserRoleId, isModerator) {
		var response = Repository.post('OnlineClass/JoinOnlineClass', {
			userId: userId,
			onlineClassId: onlineClassId,
			organisationUserRoleId: organisationUserRoleId,
			isModerator: isModerator,
		});
		return response;		
	},

	joinOnlineClassAsModerator(meetingReference, password) {
		var response = Repository.get('OnlineClass/JoinOnlineClassAsModerator', {
			params: {
				meetingReference: meetingReference,
				password: password
			}
		});
		return response;
	},

	joinOnlineClassAsPublic(onlineClassRef, displayName, email) {
		var response = Repository.post('OnlineClass/JoinOnlineClassAsPublic', {
			onlineClassRef: onlineClassRef,
			displayName: displayName,
			email: email
		});
		return response;		
	},

	rescheduleOnlineClass(onlineClassId) {
		var response = Repository.post('OnlineClass/RescheduleOnlineClass', null, {
			params: {
				onlineClassId: onlineClassId
			}
		});
		return response;		
	},

	scheduleCourseOnlineClass(title, dateStart, dateEnd, presenterName, presenterEmail, courseIds, isPublic) {
		var response = Repository.post('OnlineClass/ScheduleCourseOnlineClass', {
			title: title.trim(),
			dateStart: dateStart,
			dateEnd: dateEnd,
			presenterName: presenterName.trim(),
			presenterEmail: presenterEmail.trim(),
			courseIds: courseIds,
			isPublic: isPublic,
		});
		return response;		
	},

	scheduleOnlineClass(title, dateStart, dateEnd, presenterName, presenterEmail, isPublic, periodId, curriculumId,
						qualificationId, subjectId, languageIds) {

		var response = Repository.post('OnlineClass/ScheduleOnlineClass', {
			title: title.trim(),
			dateStart: dateStart,
			dateEnd: dateEnd,
			presenterName: presenterName.trim(),
			presenterEmail: presenterEmail.trim(),
			isPublic: isPublic,
			course: {
				curriculumId: curriculumId,
				qualificationId: qualificationId,
				subjectId: subjectId,
				languageIds: languageIds,
				periodId: periodId,
			}
		});
		return response;		
	},

	sendPresenterEmail(onlineClassId) {
		var response = Repository.get('OnlineClass/SendPresenterEmail', {
			params: {
				onlineClassId: onlineClassId
			}			
		});
		return response;		
	},

	updateOnlineClassSchedule(onlineClassId, title, dateStart, dateEnd, presenterName, presenterEmail, isPublic, periodId, curriculumId,
		qualificationId, subjectId, languageIds) {

		var response = Repository.put('OnlineClass/UpdateOnlineClassSchedule', {
			onlineClassId: onlineClassId,
			title: title.trim(),
			dateStart: dateStart,
			dateEnd: dateEnd,
			presenterName: presenterName.trim(),
			presenterEmail: presenterEmail.trim(),
			isPublic: isPublic,
			course: {
				curriculumId: curriculumId,
				qualificationId: qualificationId,
				subjectId: subjectId,
				languageIds: languageIds,
				periodId: periodId,
			}
		});
		return response;		
	},

	
}
