import Repository from './Repository';

export default {

	addUser(password, addresses, userRoles, personalInfo, organisationId) {
		var response = Repository.post('UserManagement/AddUser', {
			password: password,
			addresses: addresses,
			userRoles: userRoles, 
			personalInfo: personalInfo,
			organisationId: organisationId,
			userSourceId: 1
		});
		return response;
	},

	addUserRole(userId, roleId, organisationId) {
		var response = Repository.post('UserManagement/AddUserRole', {
			userId: userId,
			roleId: roleId,
			organisationId: organisationId
		});
		return response;
	},

	checkIDNumberAvailability(value) {
		var response = Repository.get('UserManagement/CheckIDNumberAvailability', {
			params: {
				value: value
			}
		});

		return response;		
	},

	checkUsernameAvailability(value) {
		var response = Repository.get('UserManagement/CheckUsernameAvailability', {
			params: {
				value: value
			}
		});

		return response;		
	},

	getApplicationStatuses() {

		var response = Repository.get('UserManagement/GetApplicationStatuses', {
			params: {
			}
		});

		return response;
	},

	getCourses(periodIds, subjectIds, qualificationIds, languageIds, organisationId) {
		var response = Repository.get('UserManagement/GetCourses', {
			params: {
				PeriodIdsCSV: periodIds.join(','),
				SubjectIdsCSV: subjectIds.join(','),
				QualificationIdsCSV: qualificationIds.join(','),
				LanguageIdsCSV: languageIds.join(','),
				OrganisationId: organisationId,
			}
		});

		return response;		
	},

	getLinkedStudents(organisationUserRoleId) {
		var response = Repository.get('UserManagement/GetLinkedStudents', {
			params: {
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getOrganisations() {

		var response = Repository.get('UserManagement/GetOrganisations', {
			params: {
			}
		});

		return response;
	},
	
	getRoles(organisationId) {
		var response = Repository.get('UserManagement/GetRoles', {
			params: {
				organisationId: organisationId
			}
		});

		return response;
	},

	getStudentCourses(organisationUserRoleId) {
		var response = Repository.get('UserManagement/GetStudentCourses', {
			params: {		
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getStudentUserOrganisationRoles(userId) {
		var response = Repository.get('UserManagement/GetStudentUserOrganisationRoles', {
			params: {
				userId: userId
			}
		});
		return response;
	},

	getUser(userId) {

		var response = Repository.get('UserManagement/GetUser', {
			params: {
				userId: userId
			}
		});

		return response;
	},

	getUserRoles(userId) {
		var response = Repository.get('UserManagement/GetUserRoles', {
			params: {
				userId: userId
			}
		});
		return response;
	},

	getUserSources() {
		var response = Repository.get('UserManagement/GetUserSources', {
			params: {
			}
		});
		return response;
	},

	linkUserToUserCourse(organisationUserRoleId, userCourseIds) {
		var response = Repository.post('UserManagement/LinkUserToUserCourse', {
			organisationUserRoleId: organisationUserRoleId,
			userCourseIds: userCourseIds,
		});
		return response;		
	},

	setUserActiveStatus(userId, isActive) {
		var response = Repository.put('UserManagement/SetUserActiveStatus', {
			userId: userId,
			isActive: isActive
		});
		return response;		
	},

	setUserCourseApplicationStatus(courseId, organisationUserRoleId, applicationStatusId) {
		var response = Repository.post('UserManagement/SetUserCourseApplicationStatus', {
			courseId: courseId,
			organisationUserRoleId: organisationUserRoleId,
			applicationStatusId: applicationStatusId
		});
		return response;
	},

	removeUserRole(userId, roleId, organisationId) {
		var response = Repository.post('UserManagement/RemoveUserRole', {
			userId: userId,
			roleId: roleId,
			organisationId: organisationId
		});
		return response;
	},

	searchUnlinkedStudents(itemsPerPage, page, organisationUserRoleId, searchString) {

		var response = Repository.get('UserManagement/SearchUnlinkedStudents', {
			params: {
				organisationUserRoleId: organisationUserRoleId,
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString,	
				sortBy: 'idNr'
			}
		});
		
		return response;
	},

	updateUser(userId, addresses, personalInfo) {
		var response = Repository.put('UserManagement/UpdateUser', {
			userId: userId,
			addresses: addresses,
			personalInfo: personalInfo
		});
		return response;
	},	

	unlinkUserFromUserCourse(organisationUserRoleId, userCourseIds) {
		var response = Repository.post('UserManagement/UnlinkUserFromUserCourse', {
			organisationUserRoleId: organisationUserRoleId,
			userCourseIds: userCourseIds,
		});
		return response;		
	},

}
