import Repository from './Repository';

export default {

	addFunctionGroup(name, description) {
		var response = Repository.post('FunctionGroup/AddFunctionGroup', {
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},

	deleteFunctionGroup(functionGroupId) {
		var response = Repository.delete('FunctionGroup/DeleteFunctionGroup', {
			params: {		
				functionGroupId: functionGroupId
			}
		});
		return response;		
	},

	getFunctionGroups() {
		var response = Repository.get('FunctionGroup/GetFunctionGroups', {});
		return response;		
	},

	getFunctionGroup(functionGroupId) {
		var response = Repository.get('FunctionGroup/GetFunctionGroup', {
			params: {
				functionGroupId: functionGroupId
			}			
		});
		return response;		
	},

	updateFunctionGroup(functionGroupId, name, description) {
		var response = Repository.put('FunctionGroup/UpdateFunctionGroup', {
			functionGroupId: functionGroupId, 
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},


}
