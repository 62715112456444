import Repository from './Repository';

export default {
    getOrganisationTypes() {
        var response = Repository.get('AssessmentRole/GetOrganisationTypes');
        return response;
    },

    getPeriods(organisationTypeIdCSV) {
        var response = Repository.get('AssessmentRole/GetPeriods', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV
            }
        });
        return response;
    },

    getSubPeriods(organisationTypeIdCSV, periodIdCSV) {
        var response = Repository.get('AssessmentRole/GetSubPeriods', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV
            }
        });
        return response;
    },

    getQualifications(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV) {
        var response = Repository.get('AssessmentRole/GetQualifications', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
            }
        });
        return response;
    },

    getSubjects(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV, qualificationIdCSV) {
        var response = Repository.get('AssessmentRole/GetSubjects', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
                qualificationIdCSV: qualificationIdCSV
            }
        });
        return response;
    },

    getAssessmentTypes(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV, qualificationIdCSV, subjectIdCSV) {
        var response = Repository.get('AssessmentRole/GetAssessmentTypes', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
                qualificationIdCSV: qualificationIdCSV,
                subjectIdCSV: subjectIdCSV
            }
        });
        return response;
    },

    getTaskNumbers(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV, qualificationIdCSV, subjectIdCSV, assessmentTypeIdCSV) {
        var response = Repository.get('AssessmentRole/GetTaskNumbers', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
                qualificationIdCSV: qualificationIdCSV,
                subjectIdCSV: subjectIdCSV,
                assessmentTypeIdCSV: assessmentTypeIdCSV
            }
        });
        return response;
    },

    getRoles() {
        var response = Repository.get('AssessmentRole/GetRoles');
        return response;
    },

    getAssessmentRoles(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV, qualificationIdCSV, subjectIdCSV, assessmentTypeIdCSV, taskNumberCSV, roleIdCSV) {
        var response = Repository.get('AssessmentRole/GetAssessmentRoles', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
                qualificationIdCSV: qualificationIdCSV,
                subjectIdCSV: subjectIdCSV,
                assessmentTypeIdCSV: assessmentTypeIdCSV,
                taskNumberCSV: taskNumberCSV,
                roleIdCSV: roleIdCSV
            }
        });
        return response;
    },

    getAssessmentRolesCSV(period) {
        var response = Repository.get('AssessmentRole/GetAssessmentRolesCSV', {
            params: {		
                period: period
            }
        });
        return response;
    },

    getAssessmentRolesGrouped(organisationTypeIdCSV, periodIdCSV, subPeriodIdCSV, qualificationIdCSV, subjectIdCSV, assessmentTypeIdCSV, taskNumberCSV, roleIdCSV) {
        var response = Repository.get('AssessmentRole/GetAssessmentRolesGrouped', {
            params: {		
                organisationTypeIdCSV: organisationTypeIdCSV,
                periodIdCSV: periodIdCSV,
                subPeriodIdCSV: subPeriodIdCSV,
                qualificationIdCSV: qualificationIdCSV,
                subjectIdCSV: subjectIdCSV,
                assessmentTypeIdCSV: assessmentTypeIdCSV,
                taskNumberCSV: taskNumberCSV,
                roleIdCSV: roleIdCSV
            }
        });
        return response;
    },

    createApprovalRequestBulk(items) {
        var response = Repository.post('AssessmentRole/CreateApprovalRequestBulk', {
			items: items
		});
		return response;
    },

    deleteAssessmentRoleEntry(assessmentRoleIdCSV) {
		var response = Repository.delete('AssessmentRole/DeleteAssessmentRoleEntry', {
			params: {		
				assessmentRoleIdCSV: assessmentRoleIdCSV
			}
		});
		return response;		
	},

    approveAssessmentRoleBulkRequest(assessmentRoleIdCSV) {
        var response = Repository.post('AssessmentRole/ApproveAssessmentRoleBulkRequest', {
			assessmentRoleIdCSV: assessmentRoleIdCSV
		});
		return response;
    },
}