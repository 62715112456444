import Repository from './Repository';

export default {

	getOrganisationTypes() {
		var response = Repository.get('OrganisationType/GetOrganisationTypes', {});
		return response;
	}

}
