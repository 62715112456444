import Repository from './Repository';

export default {

	getAll() {
		var response = Repository.get('Curriculum/GetAll', {});
		return response;		
	},

	getDropdown() {
		var response = Repository.get('Curriculum/GetDropdown', {});
		return response;		
	},

}
