import Repository from './Repository';

export default {

	assignUserToCourse(organisationUserRoleId, courseIds) {
		var response = Repository.post('Course/AssignUserToCourse', {
			organisationUserRoleId: organisationUserRoleId,
			courseIdsCSV: courseIds.join(","),
		});
		return response;
	},

	getCourses(itemsPerPage, page, searchString) {
		var response = Repository.get('Course/GetCourses', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString
			}
		});
		return response;
	},

	searchCourseUsers(courseId, itemsPerPage, page, searchString) {
		var response = Repository.get('Course/SearchCourseUsers', {
			params: {
				courseId: courseId,
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString
			}			
		});
		return response;
	},

	getGuardianCourses(organisationUserRoleId) {
		var response = Repository.get('Course/GetGuardianCourses', {
			params: {		
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getTutorStudentCourses(organisationUserRoleId) {
		var response = Repository.get('Course/GetTutorStudentCourses', {
			params: {
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getTutorCentreStudentCourses(organisationId) {
		var response = Repository.get('Course/GetTutorCentreStudentCourses', {
			params: {		
				organisationId: organisationId
			}
		});
		return response;
	},

	getUserCourses(organisationUserRoleId) {
		var response = Repository.get('Course/GetUserCourses', {
			params: {		
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getUserCoursesGrouped(organisationUserRoleId) {
		var response = Repository.get('Course/GetUserCoursesGrouped', {
			params: {		
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	updateCourse(courseId, description) {
		var response = Repository.put('Course/UpdateCourse', {
			courseId: courseId,
			description: description
		});
		return response;		
	},

	getDeclarationCodes(courseId) {
		var response = Repository.get('Course/GetDeclarationCodes', {
			params: {		
				courseId: courseId
			}
		});
		return response;
	},

	getUserCourseDropdown(organisationUserRoleId, periodId, qualificationId) {
		var response = Repository.get('Course/GetLinkedUserCourseDropdown', {
			params: {
				organisationUserRoleId: organisationUserRoleId,
				periodId: periodId,
				qualificationId: qualificationId
			}
		});
		return response;
	},

	getLinkedUserCourseDropdown(periodId, languageId, qualificationId) {
		var response = Repository.get('Course/GetLinkedUserCourseDropdown', {
			params: {
				periodId: periodId,
				languageId: languageId,
				qualificationId: qualificationId
			}
		});
		return response;
	},

	unassignUserFromCourse(organisationUserRoleId, courseId) {
		var response = Repository.post('Course/UnassignUserFromCourse', {
			organisationUserRoleId: organisationUserRoleId,
			courseId: courseId,
		});
		return response;
	},

}
