import Repository from './Repository';

export default {

	getDropdown() {
		var response = Repository.get('Period/GetDropdown', {});
		return response;		
	},

	getPeriods() {
		var response = Repository.get('Period/GetPeriods', {});
		return response;		
	},

	getSubPeriods(periodId) {
		var response = Repository.get('Period/GetSubPeriods', {
			params: {		
				periodId: periodId
			}			
		});
		return response;		
	},

	getUserPeriodDropdown(organisationUserRoleId) {
		var response = Repository.get('Period/GetUserPeriodDropdown', {
			params: {
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getLinkedUserPeriodDropdown() {
		var response = Repository.get('Period/GetLinkedUserPeriodDropdown');
		return response;
	},

}
