import Repository from './Repository';

export default {

    getLessonProgress(organisationUserRoleId, periodId, qualificationId) {
        var response = Repository.get('/Progress/GetLessonProgress', {
            params: {
                organisationUserRoleId: organisationUserRoleId,
                periodId: periodId,
                qualificationId: qualificationId
            }
        });
        return response;
    },

    getSupplementaryLessonProgress(organisationUserRoleId, periodId, qualificationId) {
        var response = Repository.get('/Progress/GetSupplementaryLessonProgress', {
            params: {
                organisationUserRoleId: organisationUserRoleId,
                periodId: periodId,
                qualificationId: qualificationId
            }
        });
        return response;
    }


}