import Repository from './Repository';

export default {

	addRole(organisationTypeId, name, description) {
		var response = Repository.post('Role/AddRole', {
			organisationTypeId: organisationTypeId,
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},

	getRoles() {
		var response = Repository.get('Role/GetRoles', {});
		return response;		
	},

	getRole(roleId) {
		var response = Repository.get('Role/GetRole', {
			params: {		
				roleId: roleId
			}
		});
		return response;
	},

	deleteRole(roleId) {
		var response = Repository.delete('Role/DeleteRole', {
			params: {		
				roleId: roleId
			}
		});
		return response;		
	},

	updateRole(roleId, organisationTypeId, name, description) {
		var response = Repository.put('Role/UpdateRole', {
			roleId: roleId,
			organisationTypeId: organisationTypeId,
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},
	
}
