import Repository from './Repository';

export default {
	captureUserAssessmentMark(organisationUserRoleId, assessmentInstanceId, courseId, correct, periodId) {
		var response = Repository.post('UserAssessment/CaptureUserAssessmentMark', {
			organisationUserRoleId: organisationUserRoleId,
			assessmentInstanceId: assessmentInstanceId,
			courseId: courseId,
			correct: parseInt(correct),
			periodId: periodId
		});
		return response;
	},

	clearUserAssessmentMark(organisationUserRoleId, assessmentInstanceId, courseId, periodId) {
		var response = Repository.post('UserAssessment/CaptureUserAssessmentMark', {
			organisationUserRoleId: organisationUserRoleId,
			assessmentInstanceId: assessmentInstanceId,
			courseId: courseId,
			correct: null,
			periodId: periodId
		});
		return response;
	},

	getAnswerPaper(userAssessmentId) {
		var response = Repository.get('UserAssessment/GetAnswerPaper', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {	
				userAssessmentId: userAssessmentId
			}
		});
		return response;
	},

	getMarkedPaper(userAssessmentId) {
		var response = Repository.get('UserAssessment/GetMarkedPaper', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {	
				userAssessmentId: userAssessmentId
			}
		});
		return response;
	},

	/*removeUploadPaper(organisationUserRoleId, miAssessmentMarkId, assessmentInstanceId) {
		var response = Repository.delete('UserAssessment/RemoveUploadPaper', {
			organisationUserRoleId: organisationUserRoleId,
			miAssessmentMarkId: miAssessmentMarkId,
			assessmentInstanceId: assessmentInstanceId
		});
		return response;
	},*/

	uploadAnswerPaper(organisationUserRoleId, assessmentInstanceId, courseId, files) {
		const formData = new FormData();

		formData.append('organisationUserRoleId', organisationUserRoleId);
		formData.append('assessmentInstanceId', assessmentInstanceId);
		formData.append('courseId', courseId);
		var i = 0;
		while (i < files.length) {
			formData.append('files', files[i]);
			i++;
		}

		var response = Repository.post('UserAssessment/MarkingSystemPaperUpload', formData, 
			{"headers": {
				"Content-Type": 'multipart/form-data'
			}}
		);

		return response;
		
	},

	uploadMarkedPaper(organisationUserRoleId, assessmentInstanceId, courseId, files) {
		const formData = new FormData();

		formData.append('organisationUserRoleId', organisationUserRoleId);
		formData.append('assessmentInstanceId', assessmentInstanceId);
		formData.append('courseId', courseId);
		var i = 0;
		while (i < files.length) {
			formData.append('files', files[i]);
			i++;
		}

		var response = Repository.post('UserAssessment/UploadMarkedPaper', formData, 
			{"headers": {
				"Content-Type": 'multipart/form-data'
			}}
		);

		return response;
		
	},

	initiateMarking(externalMarkId) {
		var response = Repository.get('UserAssessment/MarkingInitiateMarking', {
			params: {
				externalMarkId: externalMarkId
			}
		});
		return response;
	},

	getPaperDownloadAuditLogs(itemsPerPage, pageNumber, searchString) {

		var response = Repository.get('UserAssessment/GetPaperDownloadAuditLogs', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: pageNumber,
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc'
			}
		});

		return response;
	},

	getPaperUploadAuditLogs(itemsPerPage, pageNumber, searchString) {

		var response = Repository.get('UserAssessment/GetPaperUploadAuditLogs', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: pageNumber,
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc'
			}
		});

		return response;
	},
	
	setDeclarationCode(organisationUserRoleId, assessmentInstanceId, markDeclarationId, assessmentStatusId, assessmentSubmissionStatusId ) {

		var response = Repository.post('UserAssessment/SetDeclarationCode', {
			organisationUserRoleId: organisationUserRoleId,
			assessmentInstanceId: assessmentInstanceId,
			markDeclarationId: markDeclarationId,
			assessmentStatusId: assessmentStatusId, 
			assessmentSubmissionStatusId: assessmentSubmissionStatusId
		});
		return response;
	},

	captureUserAssessmentComment(organisationUserRoleId, assessmentInstanceId, courseId, periodId, comment) {
		// console.log("organisationUserRoleId:", organisationUserRoleId)
		// console.log("assessmentInstanceId:", assessmentInstanceId)
		// console.log("courseId:", courseId)
		// console.log("periodId:", periodId)
		// console.log("comment:", comment)
		var response = Repository.post('UserAssessment/CaptureUserAssessmentComment', {
			organisationUserRoleId: organisationUserRoleId,
			assessmentInstanceId: assessmentInstanceId,
			courseId: courseId,
			periodId: periodId,
			comment: comment
		});
		return response;
	},
	receiveIndividualAssessment(firstname, surname, email, id, barcodeText) {
		const formData = new FormData();

		formData.append('firstname', firstname);
		formData.append('surname', surname);
		formData.append('email', email);
		formData.append('idNumber', id);
		formData.append('barcode', barcodeText);

		var response = Repository.post('UserAssessment/ReceiveIndividualAssessment', formData, 
			{
				"headers":
				{
					"Content-Type": 'multipart/form-data'
				}
			}
		);

		return response;
	},

	receiveSupplierAssessment(firstname, surname, email, trackingNumber, companyName, barcodeText) {
		const formData = new FormData();

		formData.append('firstname', firstname);
		formData.append('surname', surname);
		formData.append('email', email);
		formData.append('trackingNr', trackingNumber);
		formData.append('companyName', companyName);
		formData.append('barcode', barcodeText);

		var response = Repository.post('UserAssessment/ReceiveSupplierAssessment', formData, 
			{
				"headers":
				{
					"Content-Type": 'multipart/form-data'
				}
			}
		);

		return response;
	},

	receiveCentreAssessment(firstname, surname, email, organisationId, barcodeText) {
		const formData = new FormData();

		formData.append('firstname', firstname);
		formData.append('surname', surname);
		formData.append('email', email);
		formData.append('organisationId', organisationId);
		formData.append('barcode', barcodeText);

		var response = Repository.post('UserAssessment/ReceiveCentreAssessment', formData, 
			{
				"headers":
				{
					"Content-Type": 'multipart/form-data'
				}
			}
		);

		return response;
		// var response = Repository.post('UserAssessment/ReceiveCentreAssessment', {
		// 	firstname: firstname,
		// 	surname: surname,
		// 	email: email,
		// 	organisationId: organisationId,
		// 	barcode: barcodeText
		// });
		// return response;
	},

	getManualSubmissionsReport(startDate, endDate, csv = false) {
		var response = Repository.get('UserAssessment/GetManualSubmissionsReport', {
			params: {
				csv: csv,
				startDate: startDate,
				endDate: endDate
			}
		});

		return response;
	},
	getUserAssessments(courseId, organisationUserRoleId = null) {
		var response = Repository.get('UserAssessment/GetUserAssessments', {
			params: {
				courseId: courseId,
				organisationUserRoleId: organisationUserRoleId
			}
		});

		return response;
	},

	cancelUserAssessment(userAssessmentId) {
		var response = Repository.post('UserAssessment/CancelUserAssessment', {
			userAssessmentId: userAssessmentId
		});
		return response;
	},

	getUserActionLogs(organisationUserRoleId, taskNr, courseId) {
		var response = Repository.get('UserAssessment/GetUserActionsLogs', {
			params: {
				organisationUserRoleId: organisationUserRoleId,
				taskNr: taskNr,
				courseId: courseId
			}
		});

		return response;
	}

}
