import Repository from './Repository';

export default {

	getAll() {
		var response = Repository.get('Subject/GetAll', {});
		return response;		
	},

	getDropdown(organisationUserRoleId, languageId, qualificationId) {
		var response = Repository.get('Subject/GetDropdown', {
			params: {		
				organisationUserRoleId: (organisationUserRoleId || null),
				languageId: (languageId || null),
				qualificationCSV: (qualificationId || null),
			}				
		});
		return response;		
	},

	getDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds) {
		var response = Repository.get('Subject/GetDropdownByParameters', {
			params: {
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
			}
		});
		return response;		
	},

	getUserSubjects(organisationUserRoleId, periodId, qualificationId) {
		var response = Repository.get('Subject/GetUserSubjects', {
			params: {		
				organisationUserRoleId: (organisationUserRoleId || null),
				periodId: (periodId || null),
				qualificationId: (qualificationId || null),
			}				
		});
		return response;		
	},

}
