import Repository from './Repository';

export default {

    GetDashboardCourses(organisationUserRoleId, dateStart, dateEnd, periodId, qualificationId) {

        var response = Repository.get('Dashboard/GetDashboardCourses', {
            params: {
                organisationUserRoleId: organisationUserRoleId,
                dateStart: dateStart,
                dateEnd: dateEnd,
                periodId: periodId,
                qualificationId: qualificationId
            }
        });

        return response;
    },

}
