import Repository from './Repository';

export default {

	getLogFile(logFileName) {

		var response = Repository.get('Stuff/GetLogFile', {
			params: {
				logFileName: logFileName
			}
		});

		return response;
	},

}
