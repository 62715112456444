import Repository from './Repository';

export default {

	getUserOrganisationAudit(organisationId, userId, itemsPerPage, page, searchString) {

		var response = Repository.get('Audit/GetUserOrganisationAudit', {
			params: {
				userId: userId,
				organisationId: organisationId,
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString,	
				sortBy: '',				
			}
		});

		return response;
	},

}
