import Repository from './Repository';

export default {

	/*captureStructuredAssessmentMark(organisationUserRoleId, structuredAssessmentStructureId, mark, comment) {
		
		var response;

		response = Repository.post('StructuredAssessment/CaptureStructuredAssessmentMark', {
			organisationUserRoleId: organisationUserRoleId,	
			structuredAssessmentStructureId: structuredAssessmentStructureId,
			mark: mark,
			comment: comment || '',
		});

		return response;
	},*/

	captureStructuredAssessmentMark(organisationUserRoleId, markItems) {
		
		var response;

		response = Repository.post('StructuredAssessment/CaptureStructuredAssessmentMark', {
			organisationUserRoleId: organisationUserRoleId,	
			markItems: markItems,
		});

		return response;
	},

	getFilteredStructuredDownloads(periodId, qualificationId, subjectId, languageId, organisationId = null) {
		var response = Repository.get('StructuredAssessment/GetFilteredStructuredDownloads', {
			params: {	
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId,	
				languageId: languageId,
				organisationId: organisationId
			}
		});
		return response;
	},	

	getPortfolioBook(subPeriodId, structuredAssessmentId, organisationUserRoleId) {
		var response = Repository.get('StructuredAssessment/GetPortfolioBook', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				subPeriodId: subPeriodId,
				structuredAssessmentId: structuredAssessmentId,
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},	

	getPortfolioBookMemo(subPeriodId, structuredAssessmentId, organisationUserRoleId) {
		var response = Repository.get('StructuredAssessment/GetPortfolioBookMemo', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				subPeriodId: subPeriodId,
				structuredAssessmentId: structuredAssessmentId,
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},	

	getPresetComments(languageId) {
		var response = Repository.get('StructuredAssessment/GetPresetComments', {
			params: {
				languageId: languageId,	
			}
		});
		return response;
	},	

	getStructuredAssessmentStructure(organisationUserRoleId, courseId, subPeriodId) {
		var response = Repository.get('StructuredAssessment/GetStructuredAssessmentStructure', {
			params: {	
				organisationUserRoleId: organisationUserRoleId,
				courseId: courseId,	
				subPeriodId: subPeriodId
			}
		});
		return response;
	},	

}
