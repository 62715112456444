import Repository from './Repository';

export default {

	bulkAssessmentRoleUpdate(adminInstanceItems, bulk) {
		var response = Repository.post('Assessment/BulkAssessmentRoleUpdate', {
			adminInstanceItems: adminInstanceItems,
			bulk: bulk
		});
		return response;		
	},

	getAdminInstances(roleId, periodId, qualificationId, subjectId, assessmentTypeId, assessmentTaskNumber) {
		var response = Repository.get('Assessment/GetAdminInstances', {
			params: {	
				roleIdCSV: roleId,	
				periodIdCSV: periodId,
				qualificationIdCSV: qualificationId,
				subjectIdCSV: subjectId,
				assessmentTypeIdCSV: assessmentTypeId,
				assessmentTaskNumberCSV: assessmentTaskNumber
			}
		});
		return response;
	},

	getAnswerPaper(externalMarkId) {
		var response = Repository.get('Assessment/GetAnswerPaper', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				externalMarkId: externalMarkId
			}
		});
		return response;
	},

	getAssessmentListPdf(courseId, organisationUserRoleId) {
		var response = Repository.get('Assessment/GetAssessmentListPdf', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				courseId: courseId,
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;
	},

	getAssessmentListPdfByCourse(courseId) {
		var response = Repository.get('Assessment/GetAssessmentListPdf', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				courseId: courseId
			}
		});
		return response;
	},

	getAssessmentRoles(periodId) {
		var response = Repository.get('Assessment/GetAssessmentRoles', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/csv',
                'Accept': 'application/csv'
            },			
			params: {		
				periodId: periodId
			}
		});
		return response;
	},

	getAssessmentTypesDropdown() {
		var response = Repository.get('Assessment/GetAssessmentTypesDropdown', {});
		return response;
	},

	getTermsDropdown(periodId) {
		var response = Repository.get('Assessment/GetTermsDropdown', {
			params: {		
				periodId: periodId
			}
		});
		return response;
	},

	getCourseAssessmentsInstances(courseId) {
		var response = Repository.get('Assessment/GetCourseAssessmentsInstances', {
			params: {		
				courseId: courseId
			}
		});
		return response;
	},

	getCourseAssessmentUserInstances(periodId, languageId, qualificationId, subjectId, taskNumber) {
		var response = Repository.get('Assessment/GetCourseAssessmentUserInstances', {
			params: {		
				periodId: periodId,
				languageId: languageId,
				qualificationId: qualificationId,
				subjectId: subjectId,
				assessmentTaskNumber: (taskNumber || null),
			}
		});
		return response;
	},

	/*getCourseStudyNote(courseId) {
		var response = Repository.get('Assessment/GetCourseStudyNote', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				courseId: courseId
			}
		});
		return response;
	},*/

	getFilteredAssessmentsInstances(organisationUserRoleId, periodId, qualificationId, subjectId) {
		var response = Repository.get('Assessment/GetFilteredAssessmentsInstances', {
			params: {	
				organisationUserRoleId: organisationUserRoleId,	
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId,
			}
		});
		return response;
	},

	getFilteredInstanceDownloads(periodId, qualificationId, subjectId, languageId, organisationId = null) {
		var response = Repository.get('Assessment/GetFilteredInstanceDownloads', {
			params: {		
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId,
				languageId: languageId,
				organisationId: organisationId
			}
		});
		return response;
	},

	getMemo(assessmentInstanceId, languageId) {
		var response = Repository.get('Assessment/GetMemo', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				assessmentInstanceId: assessmentInstanceId,
				languageId: languageId
			}
		});
		return response;
	},

	getOrgRoleIdAssessmentInstances(organisationUserRoleId, courseId) {
		var response = Repository.get('Assessment/GetOrgRoleIdAssessmentInstances', {
			params: {		
				organisationUserRoleId: organisationUserRoleId,
				courseId: courseId
			}
		});
		return response;		
	},

	async getQuestionPaper(assessmentInstanceId, languageId) {
		var response = await Repository.get('Assessment/GetQuestionPaper', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				assessmentInstanceId: assessmentInstanceId,
				languageId: languageId
			}
		});
		return response;
	},

	getTaskCover(organisationUserRoleId, assessmentInstanceIds, organisationId = null) {
		var response = Repository.get('Assessment/GetTaskCover', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				organisationUserRoleId: organisationUserRoleId,
				assessmentInstanceIds: assessmentInstanceIds.join(),
				organisationId: organisationId
			}
		});
		return response;
	},

	getTaskDropdown(periodId, qualificationId, subjectId) {
		var response = Repository.get('Assessment/GetTaskDropdown', {
			params: {		
				periodId: periodId,
				qualificationId: (qualificationId || null),
				subjectId: (subjectId || null),
			}
		});
		return response;
	},

	getTaskNumberDropdown(periodIdCSV, qualificationIdCSV, subjectIdCSV) {
		var response = Repository.get('Assessment/GetTaskNumberDropdown', {
			params: {		
				periodIdCSV: periodIdCSV,
				qualificationIdCSV: qualificationIdCSV,
				subjectIdCSV: subjectIdCSV,
			}
		});
		return response;
	},
	
	getAssessmentComments() {
		var response = Repository.get('Assessment/GetAssessmentComments');
		return response;
	},
	
	updateAssessmentRole(assessmentRoleId, assessmentInstanceId,
						questionPaperDownloadStart, questionPaperDownloadEnd,
						memoDownloadStart, memoDownloadEnd,
						answerPaperUploadStart, answerPaperUploadEnd,
						markedPaperDownloadStart, markedPaperDownloadEnd,
						markCaptureStart, markCaptureEnd,
						markAvailableStart, markAvailableEnd) {

		var response = Repository.put('Assessment/UpdateAssessmentRole', {
			assessmentRoleId: assessmentRoleId,
			assessmentInstanceId: assessmentInstanceId,
			questionPaperDownloadStart: questionPaperDownloadStart,
			questionPaperDownloadEnd: questionPaperDownloadEnd,
			memoDownloadStart: memoDownloadStart,
			memoDownloadEnd: memoDownloadEnd,
			answerPaperUploadStart: answerPaperUploadStart,
			answerPaperUploadEnd: answerPaperUploadEnd,				
			markedPaperDownloadStart: markedPaperDownloadStart,
			markedPaperDownloadEnd: markedPaperDownloadEnd,
			markCaptureStart: markCaptureStart,
			markCaptureEnd: markCaptureEnd,
			markAvailableStart: markAvailableStart,
			markAvailableEnd: markAvailableEnd																	
		});
		return response;		
	},

	uploadAssessmentRoles(files) {
		const formData = new FormData();

		var i = 0;
		while (i < files.length) {
			formData.append('files', files[i]);
			i++;
		}

		var response = Repository.post('Assessment/UploadAssessmentRoles', formData, 
			{"headers": {
				"Content-Type": 'multipart/form-data'
			}}
		);

		return response;
	},

	getOrganisations() {
		var response = Repository.get('Assessment/GetOrganisations');
		return response;
	},

	getQuestionPaperDownloadAuditLogs(itemsPerPage, pageNumber, searchString) {

		var response = Repository.get('Assessment/GetQuestionPaperDownloadAuditLogs', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: pageNumber,
				searchString: searchString,	
				sortBy: 'dateCreated',
				OrderDirection: 'desc'
			}
		});

		return response;
	},

	getFormats() {
		return {
			data: [{
				key: 1,
				value: 'Print'
			}]
		}
	}
}
