import Repository from './Repository';

export default {

	getAll() {
		var response = Repository.get('Qualification/GetAll', {});
		return response;		
	},

	getDropdown(organisationUserRoleId) {
		var response = Repository.get('Qualification/GetDropdown', {
			params: {		
				useConcat: true,
				organisationUserRoleId: (organisationUserRoleId || null),
			}			
		});
		return response;		
	},

	getUserQualificationDropdown(organisationUserRoleId, periodId) {
		var response = Repository.get('Qualification/GetUserQualificationDropdown', {
			params: {
				organisationUserRoleId: organisationUserRoleId,
				periodId: periodId
			}
		});
		return response;
	},

	getLinkedUserQualificationDropdown(periodId, languageId) {
		var response = Repository.get('Qualification/GetLinkedUserQualificationDropdown', {
			params: {
				periodId: periodId,
				languageId: languageId
			}
		});
		return response;
	},

	getDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds, excludeFoundationPhase = false) {
		var response = Repository.get('Qualification/GetDropdownByParameters', {
			params: {		
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
				ExcludeFoundationPhase: excludeFoundationPhase
			}				
		});
		return response;		
	},

}
