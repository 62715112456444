import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import store from '@/store'
import router from '@/router'
import olpauth from '@/plugins/olpauth'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMask from 'v-mask'

require('@/css/style.scss')

library.add(fas)
library.add(far)
library.add(fal)
Vue.component('fa', FontAwesomeIcon)

Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  i18n,
  olpauth,
  render: h => h(App)
}).$mount('#app')
