//import store from '@/store'

const funcs = {

    currentYear() {
        const d = new Date();
        return parseInt(d.getFullYear());
    },

    formatDate(time, includeYear) {
        var result = '';
        var milliseconds = time * 1000;
        var dateObject = new Date(milliseconds);
        var options = { month: 'short', day: 'numeric' };

        if (includeYear === true) {
            options = { month: 'short', day: 'numeric', year: 'numeric' };
        } 

        result = dateObject.toLocaleString("en-GB", options);

        return result;
    },

    formatDateRange(startTime, endTime, includeYear=false) {
        var result = '';
        var startDate = this.formatDate(startTime, includeYear);
        var endDate = this.formatDate(endTime, includeYear);

        if (startDate == endDate) {
            result = startDate;
        } else {
            result = startDate + ' - ' + endDate;
        }
  
        return result;
    },

    formatDateTime(time) {
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ]
        var result = '';
        var milliseconds = time * 1000;
        var dateObject = new Date(milliseconds);
        result = dateObject.getDate() + " " + months[dateObject.getMonth()] + " " + dateObject.getFullYear()
        if (dateObject.getDate() < 10) {
            result = ' ' + result
        }
        result = result + ' ' + this.formatHHMM(time);

        return result;
    },


    formatDateTimeRange(startTime, endTime) {
        var result = '';
        var startDate = this.formatDateTime(startTime);
        var endDate = this.formatDateTime(endTime);

        if (startTime == endTime) {
            result = startDate;
        } else {
            result = startDate + ' - ' + endDate;
        }
  
        return result;
    },

    formatCompactDateTimeRange(startTime, endTime) {
        var result = '';
        var startDate = this.formatDateTime(startTime);
        var endDate = this.formatDateTime(endTime);

        if (startTime == endTime) {
            result = startDate;
        } else {
            result = startDate + '<br />' + endDate;
        }
  
        return result;
    },

    formatDuration(startTime, endTime) {
        // NOTE: This currently only formats for minutes, but will be updated on a per need basis
        var result = '0';
        var min = Math.floor((endTime - startTime + 1) / 60);

        result= min + ' min';

        return result;
    },

    formatTime(time) {
        var result = '';
        var milliseconds = time * 1000;
        var dateObject = new Date(milliseconds);
        var options = { hour: 'numeric', minute: 'numeric' };

        result = dateObject.toLocaleString("en-GB", options);

        return result;
    },

    formatYYYYMMDD(time) {
        var result = '';
        var milliseconds = time * 1000;
        var d = new Date(milliseconds);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        result = [year, month, day].join('-');

        return result;
    },

    formatHHMM(time) {
        var result = '';
        var milliseconds = time * 1000;
        var d = new Date(milliseconds);
        var hour = '' + d.getHours();
        var minute = '' + d.getMinutes();

        if (hour.length < 2) 
            hour = '0' + hour;
        if (minute.length < 2) 
            minute = '0' + minute;

        result = [hour, minute].join(':');

        return result;
    },

    MsToMMSS(ms){
        var msTos = ms / 1000
        var h = Math.floor(msTos / 3600).toString().padStart(2,'0'),
            m = Math.floor(msTos % 3600 / 60).toString().padStart(2,'0'),
            s = Math.floor(msTos % 60).toString().padStart(2,'0');
        
        return h + 'h' + m + 'm' + s + 's';
    },

    getCurrentUTCTime() {
        return Math.floor(Date.now() / 1000);
    },

    localUnixToUTC(epoch) {
        const date = new Date();
        const offset = date.getTimezoneOffset() * 60;
        return epoch + offset
    },

    toEndOfDayTime(date) {
        var d = new Date(date.substr(0, 10) + ' 23:59:59 GMT+00:00');
        var result = (d.getTime() / 1000) + d.getTimezoneOffset() * 60;
        return result;
    },

    toStartOfDayTime(date) {
        var d = new Date(date.substr(0, 10) + ' 00:00:00 GMT+00:00');
        var result = (d.getTime() / 1000) + d.getTimezoneOffset() * 60 + 1;
        return result;        
    },

    toLocalEndOfDayTime(date) {
        var result = new Date(date.substr(0, 10) + ' 23:59:59').getTime() / 1000;
        return result;
    },

    toLocalStartOfDayTime(date) {
        var result = (new Date(date.substr(0, 10) + ' 00:00:00').getTime() / 1000);
        return result;        
    },

    dateAndTimeToEpoch(date, time) {
        return new Date(date + " " + time).getTime() / 1000
    },

    dateToEpoch(date) {
        if (date) return new Date(date).getTime() / 1000
        return null
    }


}
export default funcs