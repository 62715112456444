import Repository from './Repository';

export default {    
    getAssessmentStructure(periodId, qualificationId, subjectId) {
		var response = Repository.get('AssessmentAdmin/GetAssessmentStructure', {
			params: {		
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId
			}
		});
		return response;
	},

    getFormats(qualificationIds=null, periodIds=null, languageIds=null, subjectIds=null) {
        var response = Repository.get('AssessmentAdmin/GetAssessmentInstanceFormats', {
            params: {		
                QualificationIdCSV: qualificationIds,
                PeriodIdCSV: periodIds,
                LanguageIdCSV: languageIds,
                SubjectIdCSV: subjectIds
            }
        });
        return response
    },

    createAssessment(assessment) {
        var response = Repository.post('AssessmentAdmin/CreateAssessment', assessment);
      
        return response;
    },

    deleteAssessment(assessmentId) {
        var response = Repository.delete('AssessmentAdmin/DeleteAssessment', {
			params: {
				AssessmentId: assessmentId
			}
		});
        return response;
    },

    createInstance(assessment) {
        var response = Repository.post('AssessmentAdmin/CreateInstance', assessment);
      
        return response;
    },

    getInstanceTypes(qualificationIds, periodIds, languageIds, subjectIds) {
        var response = Repository.get('AssessmentAdmin/GetAssessmentInstanceTypes', {
			params: {		
				QualificationIdCSV: qualificationIds,
				PeriodIdCSV: periodIds,
				LanguageIdCSV: languageIds,
                SubjectIdCSV: subjectIds
			}
		});
        return response
    },

    getAssessmentWeights(periodId, qualificationId, subjectId) {
        var response = Repository.get('AssessmentAdmin/GetAssessmentWeights', {
			params: {		
				periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId
			}
		});
      
        return response;
    },

    setAssessmentWeights(weights) {
        var response = Repository.post('AssessmentAdmin/SetAssessmentWeights', weights);
      
        return response;
    },

    updateTaskNumber(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateTaskNumber', data);
      
        return response;
    },

    updateTaskType(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateTaskType', data);
      
        return response;
    },

    updateTerm(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateTerm', data);
      
        return response;
    },

    updateFormat(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateFormat', data);
      
        return response;
    },

    updateTotal(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateTotal', data);
      
        return response;
    },

    updateInstanceDescription(data) {
        var response = Repository.patch('AssessmentAdmin/UpdateInstanceDescription', data);
      
        return response;
    },

    getInstanceFileDetails(AssessmentInstanceIdsCSV) {
        var response = Repository.get('AssessmentAdmin/GetInstanceFileDetails', {
			params: {		
				AssessmentInstanceIdsCSV: AssessmentInstanceIdsCSV
			}
		});

        return response
    },

    uploadFile(files, assessmentInstanceIdsCSV, assessmentInstanceFileTypeId) {
        const formData = new FormData();

		formData.append('AssessmentInstanceIdsCSV', assessmentInstanceIdsCSV);
		formData.append('AssessmentInstanceFileTypeId', assessmentInstanceFileTypeId);
        for (var file of files) {
			formData.append('Files', file);
		}

		var response = Repository.post('AssessmentAdmin/UploadFile', formData, 
			{"headers": {
				"Content-Type": 'multipart/form-data'
			}}
		);

		return response;
    },

    deleteFile(AssessmentInstanceIdsCSV, assessmentInstanceFileTypeId) {
        var response = Repository.get('AssessmentAdmin/DeleteFile', {
            params: {
                AssessmentInstanceIdsCSV: AssessmentInstanceIdsCSV,
                assessmentInstanceFileTypeId: assessmentInstanceFileTypeId
            }
        })
        return response
    },

    downloadFile(assessmentInstanceId, assessmentInstanceFileTypeId) {
        var response = Repository.get('AssessmentAdmin/DownloadFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				AssessmentInstanceId: assessmentInstanceId,
				AssessmentInstanceFileTypeId: assessmentInstanceFileTypeId
			}
		});
		return response;
    },

    publishInstances(assessmentInstanceIdsCSV) {
        var response = Repository.post('AssessmentAdmin/PublishInstances', {
            AssessmentInstanceIdsCSV: assessmentInstanceIdsCSV
        });

        return response;
    },

    bulkAssignDates(dateStart, dateEnd, assessmentInstanceIdsCSV) {
        var response = Repository.post('AssessmentAdmin/BulkAssignDates', {
            dateStart: dateStart,
            dateEnd: dateEnd,
            assessmentInstanceIdsCSV: assessmentInstanceIdsCSV
        });

        return response;
    },

    finaliseStructure(periodId, qualificationId, subjectId) {
        var response = Repository.post('AssessmentAdmin/FinaliseStructure', {
            periodId: periodId,
            qualificationId: qualificationId,
            subjectId: subjectId
        });
        
        return response;
    },

    copyAssessmentStructure(data) {
        var response = Repository.post('AssessmentAdmin/CopyAssessmentStructure', data)
        return response
    },

    refreshStatus(AssessmentInstanceIdsCSV) {
        var response = Repository.get('AssessmentAdmin/GetAssessmentInstanceStatus', {
            params: {
                AssessmentInstanceIdsCSV: AssessmentInstanceIdsCSV
            }
        })
        return response
    },

    getAssessmentInstancesCSV(periodId, qualificationId, subjectId) {
        var response = Repository.get('AssessmentAdmin/GetAssessmentInstancesCSV', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'text/csv',
                'Accept': 'text/plain'
            },			
			params: {		
				periodId: periodId,
                qualificationId: qualificationId,
                subjectId: subjectId
			}
		});
		return response;
    },

    splitOrganisationInstances(assessmentInstanceId) {
        var response = Repository.get('AssessmentAdmin/SplitOrganisationInstances', {
            params: {
                assessmentInstanceId: assessmentInstanceId
            }
        })
        return response
    }
}