import Repository from './Repository';

export default {

    GetTempUsers() {
        var response = Repository.get('User/GetTempPasswords');
        return response;
    },

    CreateTempPassword(userId, organisationUserRoleId) {
        var response = Repository.post('User/CreateTempPassword', {
            userId: userId,
            organisationUserRoleId: organisationUserRoleId
		});
    
        return response;
      },

}
