import Repository from './Repository';

export default {
  getLessons(courseId, organisationUserRoleId) {
    var response = Repository.get('Lesson/GetLessons', {
      params: {
        courseId: courseId,
        organisationUserRoleId: organisationUserRoleId
      }
    });
    return response;
  },

  setLessonStatus(lessonId, isCompleted, organisationUserRoleId) {
    var response = Repository.patch('Lesson/SetLessonStatus', {
        lessonId: lessonId,
        isCompleted: isCompleted,
        organisationUserRoleId: parseInt(organisationUserRoleId)
    });

    return response;
  },

  setResourceMark(resourceId, organisationUserRoleId, mark, markTotal) {
    var response = Repository.patch('Lesson/SetResourceMark', {
      resourceId: resourceId,
      organisationUserRoleId: parseInt(organisationUserRoleId),
      mark: mark,
      markTotal: markTotal
    });

    return response;
  },

  setResourceStatus(resourceId, isCompleted, organisationUserRoleId) {
    var response = Repository.patch('Lesson/SetResourceStatus', {
      resourceId: resourceId,
      organisationUserRoleId: parseInt(organisationUserRoleId),
      isCompleted: isCompleted
    });

    return response;
  },

  reportAProblem(courseId, lessonId, resourceId, organisationUserRoleId, message, details, improvement) {
    var response = Repository.post('Lesson/LogResourceProblem', {
      courseId: courseId,
      lessonId: lessonId,
      resourceId: resourceId,
      organisationUserRoleId: parseInt(organisationUserRoleId),
      message: message,
      details: details,
      improvement: improvement
    });

    return response;
  },

  setResourceRating(resourceId, organisationUserRoleId, rating) {
    var response = Repository.patch('Lesson/SetResourceRating', {
      resourceId: resourceId,
      organisationUserRoleId: parseInt(organisationUserRoleId),
      rating: rating
    });

    return response;
  },

  logViewLessonResourceList(courseId, lessonId, organisationUserRoleIdTrigger, organisationUserRoleId) {
    var response = Repository.post('Lesson/logViewLessonResourceList', {
      courseId: courseId,
      lessonId: lessonId,
      organisationUserRoleIdTrigger: organisationUserRoleIdTrigger,
      organisationUserRoleId: organisationUserRoleId
    });

    return response;
  },

  logOpenResource(courseId, lessonId, resourceId, organisationUserRoleIdTrigger, organisationUserRoleId) {
    var response = Repository.post('Lesson/logOpenResource', {
      courseId: courseId,
      lessonId: lessonId,
      resourceId: resourceId,
      organisationUserRoleIdTrigger: organisationUserRoleIdTrigger,
      organisationUserRoleId: organisationUserRoleId
    });

    return response;
  },

  logLessonListLoad(courseId, organisationUserRoleIdTrigger, organisationUserRoleId) {
    var response = Repository.post('Lesson/logLessonListLoad', {
      courseId: courseId,
      organisationUserRoleIdTrigger: organisationUserRoleIdTrigger,
      organisationUserRoleId: organisationUserRoleId
    });

    return response;
  }
}
