import Repository from './Repository';

export default {

	addUser(username, email, firstname, surname, password) {

		var response = Repository.post('User/AddUser', {
			username: username,
			email: email,
			firstname: firstname,
			surname: surname,
			password: password,
		});

		return response;
	},

	authenticate(username, password) {
		
		var response = Repository.post('User/Authenticate', {
			username: username,
			password: password,
		});

		return response;
	},

	authenticateSwitch(username, password, organisationUserRoleId) {

		var response = Repository.post('User/Authenticate', {
			username: username,
			password: password,
			organisationUserRoleId: organisationUserRoleId
		});

		return response;
	},

	confirmUsername(userId, token) {
		var response = Repository.put('User/ConfirmUsername', {
			userId: userId,
			token: token
		});
		return response;		
	},

	deleteUser(userId) {
		var response = Repository.delete('User/DeleteUser', {
			params: {
				userId: userId
			}
		});
		return response;		
	},

	getDropdown(linkToOrganisationRoleId) {

		var response = Repository.get('User/GetDropdown', {
			params: {
				linkToOrganisationRoleId: linkToOrganisationRoleId	
			}
		});

		return response;
	},
	
	getDropdownWithStudentNumber(linkToOrganisationRoleId = null) {

		var response = Repository.get('User/GetStudentsDropdown', {
			params: {
				linkToOrganisationRoleId: linkToOrganisationRoleId
			}
		});

		return response;
	},

	getDropdownByQualification(qualificationId, periodId, subjectId, languageId) {

		var response = Repository.get('User/GetDropdown', {
			params: {
				qualificationId: qualificationId,
				periodId: periodId,
				subjectId: subjectId,
				languageId: languageId	
			}
		});

		return response;
	},

	getNewUsername(userId, token) {
		var response = Repository.get('User/GetNewUsername', {
			params: {
				userId: userId,
				token: token
			}
		});
		return response;		
	},

	getStudentDropdown(qualificationId, periodId, subjectId, languageId, organisationId = null) {
		var response = Repository.get('User/GetStudentsDropdown', {
			params: {
				qualificationId: qualificationId,
				periodId: periodId,
				subjectId: subjectId,
				languageId: languageId,
				organisationId: organisationId
			}
		});

		return response;
	},

	getUser(userId) {

		var response = Repository.get('User/GetUser', {
			params: {
				userId: userId	
			}
		});

		return response;
	},	

	getUsersByStudentNumber(studentNumber) {

		var response = Repository.get('User/GetUsersByStudentNumber', {
			params: {
				studentNumber: studentNumber
			}
		});

		return response;
	},

	getUsers(itemsPerPage, page, searchString, userSourceId=0, isActive=true) {

		var response = Repository.get('User/GetUsers', {

			params: {
				isActive: isActive,
				pageSize: itemsPerPage,
				pageNumber: page,
				userSourceId: userSourceId,
				searchString: searchString,	
				sortBy: 'username'
			}
		});
		
		return response;
	},
	

	recoverPassword(username) {

		var response = Repository.post('User/RecoverPassword', {
			username: username
		});

		return response;
	},

	resetPassword(userId, password, token) {

		var response = Repository.post('User/ResetPassword', {
			userId: userId,
			password: password,
			token: token 
		});

		return response;
	},
	
	updateUser(userId, username, email, firstname, surname) {
		var response = Repository.put('User/UpdateUser', {
			userId: userId,
			username: username,
			email: email,
			firstname: firstname,
			surname: surname
		});
		return response;		
	},

	updateUsername(email, password) {
		var response = Repository.put('User/UpdateUsername', {
			email: email,
			password: password
		});
		return response;
	},

	switchToEmailUsername(password) {
		var response = Repository.put('User/SwitchToEmailUsername', {
			password: password
		});
		return response;
	},

	markingLogin() {
		var response = Repository.get('User/MarkingSystemLogin');
		return response;		
	},

	switchUser(userId, organisationUserRoleId) {
		var response = Repository.post('User/SwitchUser', {
			userId: userId,
			organisationUserRoleId: organisationUserRoleId
		});
		return response;
	},

	unSwitchUser() {
		var response = Repository.post('User/UnSwitchUser');
		return response;
	}

}
