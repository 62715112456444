import Vue from 'vue';
import store from '@/store';

const OLPAuth  = {
  install(Vue) {
    
    Vue.prototype.hasAccess = (f) => {
      var result = false;
      if (Object.prototype.hasOwnProperty.call(authFunctionEnum, f)) {
        result = determineAcess(authFunctionEnum[f]);
      }
      return result;
    }

    Vue.hasAccess = (f) => {
      var result = false;
      if (Object.prototype.hasOwnProperty.call(authFunctionEnum, f)) {
        result = determineAcess(authFunctionEnum[f]);
      }
      return result;      
    }

    function determineAcess(functionId) {
      var result = false;
      var auth = store.state.auth;
      var functions;
      var i;  

      if (auth.authenticated === true) {
        if (typeof(store.state.auth.role) != "undefined") {
          if (Object.prototype.hasOwnProperty.call(store.state.auth.role, 'functions')) {
            functions = store.state.auth.role.functions;
            i = 0;
            while ((i < functions.length) && !result) {
              result = functions[i]['functionId'] == functionId;
              i++;
            }
          }
        }
      }
      
      return result;
    }

  }
}

// Enumaration that maps the IDs of functions as defined on server

const authFunctionEnum = {
  'additional.resources.delete': 31,
  'additional.resources.download': 29,
  'additional.resources.upload': 30,
  'additional.resources.view': 28,
  'assessment.document.download': 11,
  'assessment.document.upload': 12,
  'calendar.screen': 8,
  'capture.per.assessment': 16,
  'capture.per.learner': 15,
  'course.content.delete': 7,
  'course.content.download': 5,
  'course.content.upload': 6,
  'course.content.view': 4,
  'exam.downloads': 23,
  'general.documents': 32,
  'learner.groups': 2,
  'learner.screen': 1,
  'memo.downloads': 24,
  'menu.assessments.guardian': 22,
  'menu.assessments.learner': 26,
  'menu.assessments.tutor': 10,
  'online.classes': 9,
  'progress.screen': 27,
  'report.final': 18,
  'report.progress': 20,
  'report.submission': 17,
  'report.term': 19,
  'task.cover': 21,
  'task.memo.upload': 13,
  'task.question.upload': 14,
  'tasks.home.school.only': 25,
  'week.screen': 3,
}

Vue.use(OLPAuth);

export default OLPAuth;

/*export default {
  // called by Vue.use(FirstPlugin)
  install(Vue) {
    Vue.prototype.hasAccess = () => {
      alert("Check role access")
    }
  }
}*/

