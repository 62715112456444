import Repository from './Repository';

export default {

	getAll() {
		var response = Repository.get('Language/GetAll', {});
		return response;		
	},

	getDropdown() {
		var response = Repository.get('Language/GetDropdown', {});
		return response;		
	},

	getLinkedUserLanguageDropdown(periodId) {
		var response = Repository.get('Language/GetLinkedUserLanguageDropdown', {
			params: {
				periodId: periodId
			}
		});
		return response;
	},

	getDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds) {
		var response = Repository.get('Language/GetDropdownByParameters', {
			params: {		
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
			}				
		});
		return response;		
	},

}
