import Repository from './Repository';

export default {

	getFile(fileReferenceId) {
		var response = Repository.get('File/GetFile', {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },			
			params: {		
				fileReferenceId: fileReferenceId
			}
		});
		return response;
	},

}
