import Repository from './Repository';

export default {
	getCourses(organisationId, periodId) {
		var response = Repository.get('CourseGroup/GetCourses', {
            params: {
                organisationId: organisationId,
                periodId: periodId
            }
        });
        return response;
		
	},

    getCourseGroups(courseId, searchString, includeInactive = false) {
        var response = Repository.get('CourseGroup/GetCourseGroups', {
            params: {
                courseId: courseId,
                searchString: searchString,
                includeInactive: includeInactive
            }
        });
        return response;
    },

    createCourseGroup(name, courseId, allowOnlineClasses, allowForum, sourceId, parentId) {
		var response = Repository.post('CourseGroup/CreateCourseGroup', {
			name: name,
			courseId: courseId,
            allowOnlineClasses: allowOnlineClasses,
            allowForum: allowForum,
            sourceId: sourceId,
            parentId: parentId
		});
		return response;		
	},

    updateCourseGroup(courseGroupId, name, allowOnlineClasses, allowForum) {
		var response = Repository.put('CourseGroup/UpdateCourse', {
			courseGroupId: courseGroupId,
			name: name,
			allowOnlineClasses: allowOnlineClasses,
			allowForum: allowForum
		});
		return response;		
	},

    deleteCourseGroup(courseGroupId) {
		var response = Repository.delete('CourseGroup/DeleteCourseGroup', {
			params: {		
				courseGroupId: courseGroupId
			}
		});
		return response;
	},

    getCourseGroupUsers(courseGroupId, itemsPerPage, page, searchString, studentSearchString, includeInactive = false) {
        var response = Repository.get('CourseGroup/GetCourseGroupUsers', {
            params: {
                CourseGroupId: courseGroupId,
                includeInactive: includeInactive,
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString,
                studentSearchString: studentSearchString
            }
        });
        return response;
    },

    addCourseGroupUsers(organisationUserRoleIds, courseGroupId) {
		var response = Repository.post('CourseGroup/AddCourseGroupUsers', {
			organisationUserRoleIds: organisationUserRoleIds,
			courseGroupId: courseGroupId
		});
		return response;
	},

    removeCourseGroupUsers(organisationUserRoleIds, courseGroupId) {
		var response = Repository.post('CourseGroup/RemoveCourseGroupUsers', {
            organisationUserRoleIds: organisationUserRoleIds,
            courseGroupId: courseGroupId
		});
		return response;
	},

	getOrganisations() {
        var response = Repository.get('CourseGroup/GetOrganisations');
        return response;
    },

	getPeriods(organisationId) {
        var response = Repository.get('CourseGroup/GetPeriods', {
            params: {
                organisationId: organisationId
            }
        });
        return response;
    },

	getQualifications(organisationId, periodId) {
        var response = Repository.get('CourseGroup/GetQualifications', {
            params: {
                organisationId: organisationId,
                periodId: periodId
            }
        });
        return response;
    },

	getSubjects(organisationId, periodId, qualificationId) {
        var response = Repository.get('CourseGroup/GetSubjects', {
            params: {
                organisationId: organisationId,
                periodId: periodId,
				qualificationId: qualificationId
            }
        });
        return response;
    },

	getLanguages(organisationId, periodId, qualificationId, subjectId) {
        var response = Repository.get('CourseGroup/GetLanguages', {
            params: {
                organisationId: organisationId,
                periodId: periodId,
				qualificationId: qualificationId,
				subjectId: subjectId
            }
        });
        return response;
    },

    searchCourseUsers(courseId, itemsPerPage, page, searchString) {
		var response = Repository.get('CourseGroup/SearchCourseUsers', {
			params: {
				courseId: courseId,
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString
			}			
		});
		return response;
	},

    getFacilitatorsDropdown(organisationId, periodId) {
        var response = Repository.get('CourseGroup/GetFacilitatorsDropdown', {
            params: {
                organisationId: organisationId,
				periodId: periodId
            }
        });
        return response;
    },

    getSearchStudentCourses(searchString) {
        var response = Repository.get('CourseGroup/GetSearchStudentCourses', {
            params: {
                searchString: searchString
            }
        });
        return response;
    }
}