import Repository from './Repository';

export default {

    getMonthCalendar(year, monthNr, organisationUserRoleId, periodId, qualificationId) {

        var response = Repository.get('Calendar/GetMonthItems', {
            params: {
                monthNr: monthNr,
                year: year,
                organisationUserRoleId: organisationUserRoleId,
                periodId: periodId,
                qualificationId: qualificationId
            }
        });

        return response;
    },

    getWeekCalendar(year, weekNr, organisationUserRoleId, periodId, qualificationId) {

        var response = Repository.get('Calendar/GetWeekItems', {
            params: {
                WeekNr: weekNr,
                year: year,
                organisationUserRoleId: organisationUserRoleId,
                periodId: periodId,
                qualificationId: qualificationId
            }
        });

        return response;
    },

}
