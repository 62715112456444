import Repository from './Repository';

export default {
    getStructure(courseId) {
        var response = Repository.get('AdditionalResource/GetAdditionalResources', {
            params: {
                courseId: courseId
            }
        });
        return response;
    },
    getFile(courseId, path) {
        var response = Repository.get('AdditionalResource/GetFile', {
            params: {
                courseId: courseId,
                path: path
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'arraybuffer',
        });
        return response;
    }
}
