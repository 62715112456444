import Repository from './Repository';

export default {

	addRoleFunction(roleId, functionId) {
		var response = Repository.post('RoleFunction/AddRoleFunction', {
			roleId: roleId,
			functionId: functionId,
		});
		return response;		
	},

	deleteRoleFunction(roleFunctionId) {
		var response = Repository.delete('RoleFunction/DeleteRoleFunction', {
			params: {		
				roleFunctionId: roleFunctionId
			}
		});
		return response;		
	},

}
