import Repository from './Repository';

export default {

	addOrganisation(parentId, organisationTypeId, name) {
		var response;
		if (parentId <= 0) {
			response = Repository.post('Organisation/AddOrganisation', {
				organisationTypeId: organisationTypeId,
				name: name.trim(),
			});			
		} else {
			response = Repository.post('Organisation/AddOrganisation', {
				parentId: parentId,
				organisationTypeId: organisationTypeId,
				name: name.trim(),
			});
		}

		return response;		
	},

	deleteOrganisation(organisationId) {
		var response = Repository.delete('Organisation/DeleteOrganisation', {
			params: {
				OrganisationId: organisationId
			}
		});
		return response;		
	},

	getOrganisations(itemsPerPage, page, searchString, organisationCode, topLevel = false) {
		var response = Repository.get('Organisation/GetOrganisations', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString,	
				sortBy: 'organisationname',
				organisationCode: organisationCode,
				topLevel: topLevel
			}			
		});
		return response;
	},

	getOrganisation(organisationId) {
		var response = Repository.get('Organisation/GetOrganisation', {
			params: {
				OrganisationId: organisationId
			}			
		});
		return response;
	},

	getOrganisationUsers(itemsPerPage, page, searchString, organisationId) {
		var response = Repository.get('Organisation/GetOrganisationUsers', {
			params: {
				pageSize: itemsPerPage,
				pageNumber: page,
				searchString: searchString,	
				sortBy: 'email',				
				OrganisationId: organisationId
			}
		});
		return response;		
	},

	updateOrganisation(parentId, organisationId, organisationTypeId, name) {
		var response;

		response = Repository.put('Organisation/UpdateOrganisation', {
			parentId: parentId,
			organisationId: organisationId,
			organisationTypeId: organisationTypeId,
			name: name.trim(),
		});

		return response;		
	},

	getDropdownByParameters(qualifcationIds, periodIds, languageIds, subjectIds, onlyTopLevel) {
		var response = Repository.get('Organisation/GetDropdownByParameters', {
			params: {		
				QualificationIdCSV: (qualifcationIds.length > 0) ? qualifcationIds.join(',') : null,
				PeriodIdCSV: (periodIds.length > 0) ? periodIds.join(',') : null,
				LanguageIdCSV: (languageIds.length > 0) ? languageIds.join(',') : null,
				SubjectIdCSV: (subjectIds.length > 0) ? subjectIds.join(',') : null,
				OnlyTopLevel: onlyTopLevel 
			}				
		});
		return response;		
	},

}
