<template>
  <v-app>
    <v-main>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <AppBar/>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0">
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: "Home",
  components: {
    AppBar: () => import("./AppBar"),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = to.meta.title || 'Optimi Learning Portal';
      }
    },
  }
};
</script>

<style scoped lang="scss">
</style>
