import i18n from '@/plugins/i18n'
import userUtils from "@/utils/userUtils";
export default {
    getCourses(menuRoute, role, user) {
        var response = {
            data: {
                success: true,
                pages: [
                    { title: 'ACC', icon: 'calculator', iconstyle: 'fal', color: "#3FBE37" },
                    { title: 'AGS', icon: 'wheat', iconstyle: 'fal', color: "#00184E" },
                    { title: 'AHT', icon: 'comments', iconstyle: 'fal', color: "#8CC63F" },
                    { title: 'AMP', icon: 'hand-holding-seedling', iconstyle: 'fal', color: "#13C2C2" },
                    { title: 'BIE', icon: 'bible', iconstyle: 'fal', color: "#E1C3FF" },
                    { title: 'BUS', icon: 'briefcase', iconstyle: 'fal', color: "#F7E45B" },
                    { title: 'MAM', icon: 'calculator', iconstyle: 'fal', color: "#F7E45B" }
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.abr = page.title;
            page.link = '/' + menuRoute + '/' + page.title;
            page.title = i18n.t('subject.' + page.title);
        });
        return response
    },
    getAdminPages() {
        var response = {
            data: {
                success: true,
                pages: [                    
                    { title: 'Online Classes', icon: 'desktop', iconstyle: 'fal', link: '/admin/onlineClasses' },
                    { title: 'Courses', icon: 'books', iconstyle: 'fal', link: '/admin/courses' },
                    { title: 'Assessment Roles', icon: 'clipboard-list-check', iconstyle: 'fal', link: '/admin/assessmentroles' },
                    { title: 'Assessment Planner', icon: 'map-marked-alt', iconstyle: 'fal', link: '/admin/assessmentPlanner' },
                    { title: 'Organisations', icon: 'sitemap', iconstyle: 'fal', link: '/admin/organisations' },
                    { title: 'Groups Console', icon: 'users-cog', iconstyle: 'fal', link: '/admin/groups/console' },
                    { title: 'User Management', icon: 'users', iconstyle: 'fal', children: [
                        { title: 'Users', icon: 'users', iconstyle: 'fal', link: '/admin/usermanagement' },
                        { title: 'Register User', icon: 'user-plus', iconstyle: 'fal', link: '/admin/registeruser' },
                    ] },
                    { title: 'Roles', icon: 'user-tag', iconstyle: 'fal', link: '/admin/roles' },
                    { title: 'Temp Passwords', icon: 'key', iconstyle: 'fal', link: '/admin/temppasswords' },
                    { title: 'Logs', icon: 'history', iconstyle: 'fal', children: [
                        { title: 'Paper Download', icon: 'download', iconstyle: 'fal', link: '/admin/components/paperDownloadLogs' },
                        { title: 'Paper Upload', icon: 'upload', iconstyle: 'fal', link: '/admin/components/paperUploadLogs' },
                        { title: 'API', icon: 'code', iconstyle: 'fal', link: '/admin/components/aPILogs' },
                        { title: 'Question Paper Download', icon: 'question', iconstyle: 'fal', link: '/admin/components/questionPaperDownloadLogs' }
                    ]},
                    { title: 'Reports', icon: 'chart-line', iconstyle: 'fal', children: [
                        { title: 'Students', icon: 'user-graduate', iconstyle: 'fal', link: '/admin/reports/students' },
                        { title: 'Centres', icon: 'school', iconstyle: 'fal', link: '/admin/reports/centres' },
                        { title: 'Report Locks', icon: 'lock', iconstyle: 'fal', link: '/admin/reports/locks'}
                    ]},
                    //{ title: 'Functions', icon: 'function', iconstyle: 'fal', link: '/admin/functions'},
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    },

    getOCAdminPages() {
        var response = {
            data: {
                success: true,
                pages: [                    
                    { title: 'Online Classes', icon: 'desktop', iconstyle: 'fal', link: '/admin/onlineClasses'},
                    //{ title: 'Functions', icon: 'function', iconstyle: 'fal', link: '/admin/functions'},
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    },

    getCCAdminPages() {
        var response = {
            data: {
                success: true,
                pages: [              
                    { title: 'Organisations', icon: 'sitemap', iconstyle: 'fal', link: '/admin/organisations'},
                    { title: 'User Management', icon: 'users', iconstyle: 'fal', link: '/admin/usermanagement'},
                    { title: 'Reports', icon: 'chart-line', iconstyle: 'fal', children: [
                        { title: 'Students', icon: 'user-graduate', iconstyle: 'fal', link: '/admin/reports/students' },
                        { title: 'Centres', icon: 'school', iconstyle: 'fal', link: '/admin/reports/centres' }
                    ]},
                    { title: 'Groups Console', icon: 'users-cog', iconstyle: 'fal', link: '/admin/groups/console' },
                    //{ title: 'Functions', icon: 'function', iconstyle: 'fal', link: '/admin/functions'},
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    },
    
    getAAAdminPages() {
        var response = {
            data: {
                success: true,
                pages: [
                    { title: 'Assessment Roles', icon: 'clipboard-list-check', iconstyle: 'fal', link: '/admin/assessmentroles' },
                    { title: 'Organisations', icon: 'sitemap', iconstyle: 'fal', link: '/admin/organisations'},      
                    { title: 'User Management', icon: 'users', iconstyle: 'fal', link: '/admin/usermanagement'},
                    { title: 'Reports', icon: 'chart-line', iconstyle: 'fal', children: [
                        { title: 'Students', icon: 'user-graduate', iconstyle: 'fal', link: '/admin/reports/students' },
                        { title: 'Centres', icon: 'school', iconstyle: 'fal', link: '/admin/reports/centres' }
                    ]},
                    //{ title: 'Functions', icon: 'function', iconstyle: 'fal', link: '/admin/functions'},
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    },
    
    getIAdminPages() {
        var adminStudentDetails = userUtils.getAdminStudentDetails()
        var response = {
            data: {
                success: true,
                pages: [    
                    { title: 'Select Student', icon: 'graduation-cap', iconstyle: 'fal', link: '/admin/dashboard'},
                    { disabled:  Object.keys(adminStudentDetails).length === 0 ? true : false, title: 'Assessments', icon: 'clipboard-list-check', iconstyle: 'fal', children: [
                        { title: 'Assessment Reports', icon: 'chart-line', iconstyle: 'fal', link: '/admin/reports/students' },
                        { title: 'Downloads', icon: 'download', iconstyle: 'fal', link: '/admin/assessments/downloads' },
                        { title: 'Submissions', icon: 'upload', iconstyle: 'fal', link: '/admin/assessments/submissions' },
                    ]},
                    { title: 'Manual Marking', icon: 'edit', iconstyle: 'fal', children: [
                        { title: 'Receive Manual Assessment', icon: 'barcode-read', iconstyle: 'fal', link: '/assessments/receiveManualAssessment' },
                        { title: 'Manual Submissions Report', icon: 'file-search', iconstyle: 'fal', link: '/assessments/manualSubmissionsReport' },
                    ]},   
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    },
    getAcademicAdminPages() {
        var response = {
            data: {
                success: true,
                pages: [
                    { title: 'Assessment Planner', icon: 'map-marked-alt', iconstyle: 'fal', link: '/admin/assessmentPlanner' },
                ]
            }
        }
        response.data.pages.forEach(page => {
            page.title = i18n.t(page.title.toLowerCase());
        });
        return response
    }
}