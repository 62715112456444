import Repository from './Repository';

export default {

	getOrganisationUserRoles(userId) {
		var response = Repository.get('OrganisationUserRole/GetOrganisationUserRoles', {
			params: {
				userId: userId
			}
		});
		return response;
	},

	addOrganisationUserRole(organisationId, username, roleId) {
		var response = Repository.post('OrganisationUserRole/AddOrganisationUserRole', {
			organisationId: organisationId,
			username: username,
			roleId: roleId,
		});
		return response;
	},

	deleteOrganisationUserRole(organisationUserRoleId) {
		var response = Repository.delete('OrganisationUserRole/DeleteOrganisationUserRole', {
			params: {		
				organisationUserRoleId: organisationUserRoleId
			}
		});
		return response;		
	},

}
