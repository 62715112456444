import AssessmentRepository from './AssessmentRepository'
import AuditRepository from './AuditRepository'
import CourseRepository from './CourseRepository'
import CurriculumRepository from './CurriculumRepository'
import FileRepository from './FileRepository'
import FunctionRepository from './FunctionRepository'
import FunctionGroupRepository from './FunctionGroupRepository'
import LanguageRepository from './LanguageRepository'
import LessonsRepository from './LessonsRepository'
import MenuRepository from './MenuRepository'
import OnlineClassRepository from './OnlineClassRepository'
import OrganisationRepository from './OrganisationRepository'
import OrganisationTypeRepository from './OrganisationTypeRepository'
import OrganisationUserRoleRepository from './OrganisationUserRoleRepository'
import PeriodRepository from './PeriodRepository'
import QualificationRepository from './QualificationRepository'
import RoleRepository from './RoleRepository'
import RoleFunctionRepository from './RoleFunctionRepository'
import StructuredAssessmentRepository from './StructuredAssessmentRepository'
import SubjectRepository from './SubjectRepository'
import UserAssessmentRepository from './UserAssessmentRepository'
import UserManagementRepository from './UserManagementRepository'
import UserRepository from './UserRepository'
import CalendarRepository from './CalendarRepository'
import ThisWeekRepository from './ThisWeekRepository'
import ProgressRepository from './ProgressRepository'
import ExtraResourcesRepository from './ExtraResourcesRepository'
import TempUserRepository from './TempUserRepository'
import ReportRepository from './ReportRepository'
import OlpOtherRepository from './OlpOtherRepository'
import AssessmentRoleRepository from './AssessmentRoleRepository'
import CourseGroupRepository from './CourseGroupRepository'
import AssessmentPlannerRepository from './AssessmentPlannerRepository'

const repositories = {
	assessment: AssessmentRepository,
	audit: AuditRepository,
	course: CourseRepository,
	curriculum: CurriculumRepository,	
	file: FileRepository,
	function: FunctionRepository,
	functionGroup: FunctionGroupRepository,
	language: LanguageRepository,
	lessons: LessonsRepository,
	menu: MenuRepository,
	onlineClass: OnlineClassRepository,
	organisation: OrganisationRepository,
	organisationType: OrganisationTypeRepository,
	organisationUserRole: OrganisationUserRoleRepository,
	period: PeriodRepository,
	qualification: QualificationRepository,
	report: ReportRepository,
	role: RoleRepository,
	roleFunction: RoleFunctionRepository,
	structuredAssessment: StructuredAssessmentRepository,
	subject: SubjectRepository,
	user: UserRepository,
	userAssessment: UserAssessmentRepository,
	userManagement: UserManagementRepository,
	calendar: CalendarRepository,
	dashboard: ThisWeekRepository,
	progress: ProgressRepository,
	extraResources: ExtraResourcesRepository,
	tempUser: TempUserRepository,
	olpOther: OlpOtherRepository,
	assessmentRole: AssessmentRoleRepository,
	courseGroup: CourseGroupRepository,
	assessmentPlanner: AssessmentPlannerRepository
};

export const RepositoryFactory = {
	get: name => repositories[name]
};
