import Repository from './Repository';

export default {

	addFunction(functionGroupId, name, description) {
		var response = Repository.post('Function/AddFunction', {
			functionGroupId: functionGroupId,
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},

	deleteFunction(functionId) {
		var response = Repository.delete('Function/DeleteFunction', {
			params: {		
				functionId: functionId
			}
		});
		return response;		
	},

	getFunctions() {
		var response = Repository.get('Function/GetFunctions', {});
		return response;		
	},

	updateFunction(functionId, functionGroupId, name, description) {
		var response = Repository.put('Function/UpdateFunction', {
			functionId: functionId, 
			functionGroupId: functionGroupId, 
			name: name.trim(),
			description: description.trim(),
		});
		return response;		
	},

}
